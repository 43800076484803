/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: Poppins-ExtraLight;
    src: url(assets/font/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-Regular;
    src: url(assets/font/Poppins-Regular.ttf) format("truetype");
    font-style: normal;
}

@font-face {
    font-family: Poppins-Bold;
    src: url(assets/font/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url(assets/font/Poppins-SemiBold.ttf) format("truetype");
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

.iti--allow-dropdown, .iti--allow-dropdown input[type=tel] {
    width: calc(100% - 16px);
    border-radius: 7px;
    border: solid 1px #e2e2ea;
    background-color: #fbfbfb;
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #1b222f;
    //padding-top: 9px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
    //line-height: 35px;
    height: 41px;
}

.iti--allow-dropdown input[type=tel] {
    border: none;
    margin-left: 70px;
    width: fit-content;
    padding-top: 0px
}

.iti input, .iti input[type=text], .iti input[type=tel] {
    padding-top: 5px !important;
}

.disabled {
    opacity: 0.38;
    cursor: default;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

*, *::before, *::after {
    box-sizing: content-box;
}